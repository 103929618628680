import { AsyncMethodReturns, connectToChild } from "penpal";
import {
    RollbarSettings,
    rollbarMessage
} from "../functions/internal/helpers/rollbar";
import {
    CatalogDetailsResponse,
    CollectionDetailsResponse
} from "@switcherstudio/player-api-client";
import { Modals } from "../src/store/Modals/types";
import {
    ICreatorCustomerTicket,
    PasswordClaim
} from "../src/store/CreatorCustomers/types";

declare global {
    interface Window {
        switcherPlayerApp: any;
        Shopify: any;
    }
}

interface SwitcherPlayerCollection {
    mainDialog: HTMLDialogElement;
    mainIframe: HTMLIFrameElement;
    auxDialog: HTMLDialogElement;
    auxIframe: HTMLIFrameElement;
    mainConnection?: AsyncMethodReturns<ChildFrame>;
    auxConnection?: AsyncMethodReturns<ChildFrame>;
}

interface ChildFrame {
    setDoNotTrack: (doNotTrack: boolean) => void;
    setCatalogDetails: (params: CatalogDetailsResponse) => void;
    setCollectionDetails: (params: CollectionDetailsResponse) => void;
    loadPlayer: (
        catalogId: string,
        playerId: string,
        playlistBroadcastId: string,
        autoPlay: boolean
    ) => void;
    loadPurchaseModal: (
        type: Modals,
        playerId: string,
        playlistBroadcastId: string
    ) => void;
    setAuthorization: (ticket: ICreatorCustomerTicket) => void;
    setPasswordClaims: (
        projectId: string,
        passwordClaims: PasswordClaim[]
    ) => void;
}

const rollbarSettings = {
    environment: process.env.VITE_ENV || "local",
    version: process.env.VITE_VERSION || "1.0",
    clientKey: process.env.VITE_ROLLBAR_SERVERKEY
} as RollbarSettings;

class SwitcherPlayerApp {
    playerCollection: Record<string, SwitcherPlayerCollection[]>;
    initcontainers: HTMLCollectionOf<Element>;

    constructor() {
        this.playerCollection = {};
        this.initcontainers = document.getElementsByClassName(
            "dff402f7-5be0-4890-b831-95c5b63ddb42"
        );
    }

    async init() {
        for (let i = 0; i < this.initcontainers.length; i++) {
            const container = this.initcontainers[i] as HTMLElement;

            // init can be called multiple times, so we need to check if the container is already initialized
            if (container.dataset.isPlayerInitialized === "true") {
                continue;
            }
            container.dataset.isPlayerInitialized = "true";

            // query params from the url or container div
            const params = this.getParams(container);
            const { hostName, path } = params;

            const iframeUrl = new URL(hostName);
            iframeUrl.pathname = path;

            // create catalog elements
            const mainDialog = document.createElement("dialog");
            mainDialog.style.display = "block";
            mainDialog.style.width = "100%";
            mainDialog.style.boxSizing = "border-box";
            mainDialog.style.border = "none";
            mainDialog.style.padding = "0";
            mainDialog.style.margin = "0";
            mainDialog.style.maxWidth = "100%";
            mainDialog.style.maxHeight = "100%";
            mainDialog.style.position = "relative";

            if (container.dataset.microsite) {
                mainDialog.style.height = "100%";
            }

            const mainIframe = document.createElement("iframe");
            mainIframe.allowFullscreen = true;
            mainIframe.frameBorder = "0";
            mainIframe.scrolling = "auto";
            mainIframe.style.width = "100%";
            mainIframe.style.height = "100%";
            mainIframe.style.padding = "0";
            mainIframe.style.margin = "0 0 -5px 0";

            this.setIframeSrc(mainIframe, iframeUrl, {
                ...params,
                iframeType: "main"
            });

            // create player elements
            const auxDialog = document.createElement("dialog");
            auxDialog.style.display = "none";
            auxDialog.style.position = "fixed";
            auxDialog.style.padding = "0";
            auxDialog.style.margin = "0";
            auxDialog.style.width = "100%";
            auxDialog.style.height = "100%";
            auxDialog.style.maxWidth = "100%";
            auxDialog.style.maxHeight = "100%";
            auxDialog.style.top = "0px";
            auxDialog.style.left = "0px";
            auxDialog.style.zIndex = "9999";
            auxDialog.style.boxSizing = "border-box";
            auxDialog.style.border = "none";
            auxDialog.style.background = "none";

            const auxIframe = document.createElement("iframe");
            auxIframe.allowFullscreen = true;
            auxIframe.frameBorder = "0";
            auxIframe.scrolling = "auto";
            auxIframe.style.position = "absolute";
            auxIframe.style.top = "0";
            auxIframe.style.left = "0";
            auxIframe.style.width = "100%";
            auxIframe.style.height = "100%";
            // load iframe url intially to handle auth flow
            this.setIframeSrc(auxIframe, iframeUrl, {
                ...params,
                iframeType: "aux-player",
                // clear embed variables
                p: null,
                c: null
            });

            // append elements
            mainDialog.appendChild(mainIframe);
            container.appendChild(mainDialog);
            auxDialog.appendChild(auxIframe);
            container.appendChild(auxDialog);

            // how to reference this instance of the embed
            const collectionKey = params.c ?? params.p;

            // add to player collection
            if (!this.playerCollection[collectionKey]) {
                this.playerCollection[collectionKey] = [];
            }

            const playerInstance = {
                mainDialog: mainDialog,
                mainIframe: mainIframe,
                auxDialog: auxDialog,
                auxIframe: auxIframe
            };

            this.playerCollection[collectionKey].push(playerInstance);

            // Get the index of this instance in the collection
            const instanceIndex =
                this.playerCollection[collectionKey].length - 1;

            // prep for child connection setups
            const maxRetries = 3;
            let retryCount = 0;
            const attemptAuxEventListenerInitialization = (
                auxDialog: HTMLDialogElement,
                auxIframe: HTMLIFrameElement,
                instanceIndex: number
            ) => {
                return this.makeAuxEventListeners(auxDialog, auxIframe).then(
                    (auxConnection) => {
                        this.playerCollection[collectionKey][
                            instanceIndex
                        ].auxConnection = auxConnection;
                    }
                );
            };

            const attemptMainEventListenerInitialization = (
                collectionKey: string,
                mainDialog: HTMLDialogElement,
                mainIframe: HTMLIFrameElement,
                auxDialog: HTMLDialogElement,
                instanceIndex: number
            ) => {
                return this.makeMainEventListeners(
                    collectionKey,
                    mainDialog,
                    mainIframe,
                    auxDialog,
                    instanceIndex
                ).then((mainConnection) => {
                    this.playerCollection[collectionKey][
                        instanceIndex
                    ].mainConnection = mainConnection;
                });
            };

            // setup child connections
            attemptAuxEventListenerInitialization(
                auxDialog,
                auxIframe,
                instanceIndex
            ).catch((error) => {
                console.error("Failed to make aux event listeners:", error);
                const retryAux = () => {
                    if (retryCount < maxRetries) {
                        retryCount++;
                        console.log(
                            `Attempting to re-initialize aux event listeners (attempt ${retryCount})...`
                        );
                        setTimeout(() => {
                            attemptAuxEventListenerInitialization(
                                auxDialog,
                                auxIframe,
                                instanceIndex
                            ).catch((retryError) => {
                                console.error(
                                    "Failed to re-initialize aux event listeners:",
                                    retryError
                                );
                                retryAux(); // Recursive call for retry
                            });
                        }, 1000); // Delay of 1 second before retrying
                    } else {
                        const errorMessage =
                            "Max retries reached for aux event listeners.";
                        console.error(errorMessage);
                        rollbarMessage(rollbarSettings, errorMessage, {
                            error: error
                        });
                    }
                };
                retryAux(); // Initial retry attempt
            });

            retryCount = 0; // Reset retry count for main event listeners

            attemptMainEventListenerInitialization(
                collectionKey,
                mainDialog,
                mainIframe,
                auxDialog,
                instanceIndex
            ).catch((error) => {
                console.error("Failed to make main event listeners:", error);
                const retryMain = () => {
                    if (retryCount < maxRetries) {
                        retryCount++;
                        console.log(
                            `Attempting to re-initialize main event listeners (attempt ${retryCount})...`
                        );
                        setTimeout(() => {
                            attemptMainEventListenerInitialization(
                                collectionKey,
                                mainDialog,
                                mainIframe,
                                auxDialog,
                                instanceIndex
                            ).catch((retryError) => {
                                console.error(
                                    "Failed to re-initialize main event listeners:",
                                    retryError
                                );
                                retryMain(); // Recursive call for retry
                            });
                        }, 1000); // Delay of 1 second before retrying
                    } else {
                        const errorMessage =
                            "Max retries reached for main event listeners.";
                        console.error(errorMessage);
                        rollbarMessage(rollbarSettings, errorMessage, {
                            error: error
                        });
                    }
                };
                retryMain(); // Initial retry attempt
            });
        }

        return true;
    }

    getParams(container?: HTMLElement): Record<string, any> {
        let params = {
            hostName: container?.dataset?.hostname ?? null,
            path: container?.dataset?.path ?? null,
            loc: container?.dataset?.location ?? null,
            ajs_uid: container?.dataset?.userid ?? null,
            projectId: container?.dataset?.projectid ?? null,
            b: container?.dataset?.broadcastid ?? null,
            p: container?.dataset?.videoplayerid ?? null,
            c: container?.dataset?.catalogid ?? null,
            doNotTrack: !!window.Shopify?.customerPrivacy
                ? !window.Shopify.customerPrivacy.userCanBeTracked()
                : false,
            referrerUrl: window.location.href,
            microsite: container?.dataset?.microsite ?? false
        } as any;
        const searchParams = new URLSearchParams(window.location.search);

        const token = searchParams.get("switcher-token");
        if (token) params.token = token;

        const autoplay = searchParams.get("autoplay");
        if (autoplay) params.autoplay = autoplay;

        return params;
    }

    appendSearchParams(params: Record<string, any>, url: URL): URL {
        const newUrl = new URL(url.toString());
        const urlParams = new URLSearchParams(newUrl.search);
        Object.entries(params)
            .filter(([, v]) => v !== null && v !== undefined) // filter null and undefined params
            .forEach(([k, v]) => {
                urlParams.append(k, v);
            });
        newUrl.search = urlParams.toString();
        return newUrl;
    }

    setIframeSrc(
        ifrm: HTMLIFrameElement,
        iframeUrl: URL,
        params: Record<string, any>
    ) {
        // create local copy of iframe url
        let localUrl = new URL(iframeUrl);

        // If token is present, we must load the authorize view in the embed with the appropriate data.
        if (!!params.token && params.iframeType === "main") {
            let successUrl = new URL(iframeUrl);
            successUrl = this.appendSearchParams(
                {
                    ...params,
                    token: null
                },
                successUrl
            );
            localUrl.pathname = "/authorize";
            let authParams = {
                token: params.token,
                final_url: successUrl.href
            };
            localUrl = this.appendSearchParams(authParams, localUrl);
        } else {
            if (params) {
                localUrl = this.appendSearchParams(params, localUrl);
            }
        }

        ifrm.setAttribute("src", localUrl.href);
    }

    resize(iframe: HTMLIFrameElement, height?: number) {
        if (height) {
            iframe.style.height = `${height}px`;
        } else {
            iframe.style.height = "100%";
        }
    }

    distributeAuthorization(ticket: ICreatorCustomerTicket) {
        // loop through player collections
        Object.keys(this.playerCollection).forEach((collectionKey) => {
            // Process each instance of this collection key
            this.playerCollection[collectionKey].forEach((playerInstance) => {
                // bail out if the connections are not set
                if (
                    !playerInstance.mainConnection ||
                    !playerInstance.auxConnection
                )
                    return;

                playerInstance.mainConnection.setAuthorization(ticket);
                playerInstance.auxConnection.setAuthorization(ticket);
            });
        });
    }

    distributePasswordClaims(
        projectId: string,
        passwordClaims: PasswordClaim[]
    ) {
        // loop through player collections
        Object.keys(this.playerCollection).forEach((collectionKey) => {
            // Process each instance of this collection key
            this.playerCollection[collectionKey].forEach((playerInstance) => {
                // bail out if the connections are not set
                if (
                    !playerInstance.mainConnection ||
                    !playerInstance.auxConnection
                )
                    return;

                playerInstance.mainConnection.setPasswordClaims(
                    projectId,
                    passwordClaims
                );
                playerInstance.auxConnection.setPasswordClaims(
                    projectId,
                    passwordClaims
                );
            });
        });
    }

    private showModal(dialog: HTMLDialogElement, isAux: boolean = false) {
        if (typeof dialog.showModal === "function") dialog.showModal();
        dialog.style.position = "fixed";
        dialog.style.top = "0px";
        dialog.style.left = "0px";
        dialog.style.zIndex = "9999";
        if (isAux) {
            dialog.style.display = "block";
        }
        dispatchEvent(new Event("switcherPlayerDialogOpened"));
    }
    private closeModal(dialog: HTMLDialogElement, isAux: boolean = false) {
        if (typeof dialog.close === "function") dialog.close();
        dialog.style.position = "relative";
        dialog.style.top = "initial";
        dialog.style.left = "initial";
        dialog.style.zIndex = "initial";
        if (isAux) {
            dialog.style.display = "none";
        }
        dispatchEvent(new Event("switcherPlayerDialogClosed"));
    }

    async makeMainEventListeners(
        collectionKey: string,
        mainDialog: HTMLDialogElement,
        mainIframe: HTMLIFrameElement,
        auxDialog: HTMLDialogElement,
        instanceIndex: number
    ) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this;
        const connection = connectToChild<ChildFrame>({
            iframe: mainIframe,
            methods: {
                redirect(redirectUrl: string) {
                    const sanitizedRedirect = decodeURIComponent(
                        decodeURI(redirectUrl)
                    );
                    const url = new URL(sanitizedRedirect);
                    window.location.href = url.href;
                },
                openPlayer(
                    catalogId: string,
                    playerId: string,
                    playlistBroadcastId: string,
                    autoPlay: boolean = false
                ) {
                    _this.playerCollection[collectionKey][
                        instanceIndex
                    ].auxConnection?.loadPlayer(
                        catalogId,
                        playerId,
                        playlistBroadcastId,
                        autoPlay
                    );

                    _this.showModal(auxDialog, true);
                },
                openPurchaseModal(
                    type: Modals,
                    collectionId: string,
                    playerId: string
                ) {
                    _this.playerCollection[collectionKey][
                        instanceIndex
                    ].auxConnection?.loadPurchaseModal(
                        type,
                        collectionId,
                        playerId
                    );
                    _this.showModal(auxDialog, true);
                },
                expand() {
                    mainDialog.style.position = "fixed";
                    mainDialog.style.height = "100%";
                    mainDialog.style.aspectRatio = "initial";
                    mainDialog.style.maxWidth = "100%";
                    _this.showModal(mainDialog);
                },
                collapse(aspectRatio, maxWidth) {
                    mainDialog.style.position = "relative";
                    mainDialog.style.height = "initial";
                    mainDialog.style.aspectRatio =
                        aspectRatio === "NineBySixteen" ? "9/16" : "16/9";
                    mainDialog.style.maxWidth = !!maxWidth
                        ? `${maxWidth}px`
                        : "100%";
                    _this.closeModal(mainDialog);
                },
                /** Before aspect ratio is set by child, the dialog is initialized but hidden */
                setAspectRatio(aspectRatio) {
                    if (!!aspectRatio) {
                        mainIframe.style.position = "absolute";
                        mainIframe.style.top = "0";
                        mainIframe.style.left = "0";
                        mainDialog.style.aspectRatio =
                            aspectRatio === "NineBySixteen" ? "9/16" : "16/9";
                    } else {
                        mainIframe.style.position = "initial";
                        mainIframe.style.top = "initial";
                        mainIframe.style.left = "initial";
                        mainDialog.style.aspectRatio = "initial";
                    }
                },
                resize(height?: number) {
                    _this.resize(mainIframe, height);
                },
                distributeAuthorization(ticket: ICreatorCustomerTicket) {
                    _this.distributeAuthorization(ticket);
                },
                distributePasswordClaims(
                    projectId: string,
                    passwordClaims: PasswordClaim[]
                ) {
                    _this.distributePasswordClaims(projectId, passwordClaims);
                },
                emitEvent(eventName: string, eventData: any) {
                    mainIframe.dispatchEvent(
                        new CustomEvent(eventName, { detail: eventData })
                    );
                },
                setDialogBackgroundColor(color: string) {
                    mainDialog.style.backgroundColor = color;
                },
                setMaxWidth(maxWidth: string) {
                    mainDialog.style.maxWidth = !!maxWidth
                        ? `${maxWidth}px`
                        : "100%";
                },
                setCatalogDetails(params: CatalogDetailsResponse) {
                    _this.playerCollection[collectionKey][
                        instanceIndex
                    ].auxConnection?.setCatalogDetails(params);
                },
                setCollectionDetails(params: CollectionDetailsResponse) {
                    _this.playerCollection[collectionKey][
                        instanceIndex
                    ].auxConnection?.setCollectionDetails(params);
                }
            }
        });

        connection.promise
            .then((mainConnection) => {
                // set base events
                document.addEventListener(
                    "trackingConsentAccepted",
                    function () {
                        mainConnection.setDoNotTrack(false);
                    }
                );
            })
            .catch((error) => {
                rollbarMessage(rollbarSettings, error, null);
            });

        return connection.promise;
    }

    async makeAuxEventListeners(
        auxDialog: HTMLDialogElement,
        auxIframe: HTMLIFrameElement
    ) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this;
        const connection = connectToChild<ChildFrame>({
            iframe: auxIframe,
            methods: {
                redirect(redirectUrl: string) {
                    const sanitizedRedirect = decodeURIComponent(
                        decodeURI(redirectUrl)
                    );
                    const url = new URL(sanitizedRedirect);
                    window.location.href = url.href;
                },
                closePlayer() {
                    _this.closeModal(auxDialog, true);
                    //auxIframe.src = "";
                    // DEVNOTE: Likely need to do some cleanup of the app state in the iframe here or before this is called
                },
                closePurchaseModal() {
                    _this.closeModal(auxDialog, true);
                    // DEVNOTE: Likely need to do some cleanup of the app state in the iframe here or before this is called
                },
                distributeAuthorization(ticket: ICreatorCustomerTicket) {
                    _this.distributeAuthorization(ticket);
                },
                distributePasswordClaims(
                    projectId: string,
                    passwordClaims: PasswordClaim[]
                ) {
                    _this.distributePasswordClaims(projectId, passwordClaims);
                }
            }
        });

        connection.promise
            .then((auxConnection) => {
                // set base events
                document.addEventListener(
                    "trackingConsentAccepted",
                    function () {
                        auxConnection.setDoNotTrack(false);
                    }
                );
            })
            .catch((error) => {
                rollbarMessage(rollbarSettings, error, null);
            });

        return connection.promise;
    }
}

window.switcherPlayerApp = window.switcherPlayerApp ?? new SwitcherPlayerApp();
if (!!window.Shopify) {
    try {
        window.Shopify.loadFeatures(
            [
                {
                    name: "consent-tracking-api",
                    version: "0.1"
                }
            ],
            function (error: any) {
                if (error) {
                    throw error;
                }
            }
        );
    } catch (error) {
        rollbarMessage(rollbarSettings, error, null);
    } finally {
        window.switcherPlayerApp
            .init()
            .then(() => dispatchEvent(new Event("switcherPlayerAppLoaded")));
    }
} else {
    window.switcherPlayerApp
        .init()
        .then(() => dispatchEvent(new Event("switcherPlayerAppLoaded")));
}
